<template>
  <v-form ref="form" v-model="valid" @submit="toPayment" v-if="!loading">
    <migration v-if="[2, 5].includes(company.inputTypeId)" />
    <transformation v-if="company.inputTypeId === 3" />
    <company-address />
    <opening v-if="[1, 4].includes(company.inputTypeId)" />
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <v-btn
          right
          class="custom-btn-green rounded-0"
          @click="toPayment()"
          :loading="loadingBtn"
        >
          Salvar informações e avançar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'company-migrate-company',
  components: {
    Opening: () => import('./opening.vue'),
    Transformation: () => import('./transformation.vue'),
    Migration: () => import('./migration.vue'),
    CompanyAddress: () => import('./CompanyAddress.vue'),
  },
  data() {
    return {
      valid: false,
      isValid: false,
      loading: false,
      loadingBtn: false,
    }
  },
  computed: {
    ...mapGetters({
      partners: 'partner/partners',
      company: 'company/company',
      forceValidationCompany: 'formCompanyPartner/forceValidationCompany',
      companyIsValid: 'formCompanyPartner/companyIsValid',
    }),
  },
  methods: {
    async toPayment() {
      this.loadingBtn = true
      this.scrollTop()
      if (this.forceValidationCompany) {
        await this.$store.dispatch('formCompanyPartner/forceValidation')
        this.$refs.form.validate()
        if (this.companyIsValid) this.goToParner()
        this.loadingBtn = false
        return
      }
      this.goToParner()
      this.loadingBtn = false
    },
    goToParner() {
      let lastId = this.partners[0].id
      this.$router.push({ name: 'partner', params: { id: lastId } })
      this.loadingBtn = false
      return
    },
    scrollTop() {
      this.$vuetify.goTo(0, {
        duration: 300,
        offset: 0,
        easing: 'linear',
      })
    },
  },
  async mounted() {
    this.loading = true
    await this.$store.dispatch('company/getCurrentCompany')
    this.loading = false
    this.$nextTick(() => {
      if (this.forceValidationCompany) this.$refs.form.validate()
    })
  },
}
</script>
<style lang="scss" module>
@import '@design';
</style>
